import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '~models/user';
import { Doctor } from '~models/doctor';
import { Register } from '~models/Register';
import { Otp } from '~models/Otp';
import { Admin } from '../_models/admin/admin';
import { Doctorsignup } from '../_models/doctorsignup';
import { Patientsignup } from '../_models/patientsignup';
import { Forgotpassword } from '../_models/forgotpassword';
import { changeEmailandPhone } from '../_models/doctor/changeemailandphone';
interface role {
    token: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private currentDoctorSubject: BehaviorSubject<Doctor>;
    public currentDoctor: Observable<Doctor>;
    private currentAdminSubject: BehaviorSubject<Admin>;
    public currentAdmin: Observable<Admin>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentDoctorSubject = new BehaviorSubject<Doctor>(JSON.parse(localStorage.getItem('currentDoctor')));
        this.currentDoctor = this.currentDoctorSubject.asObservable();
        this.currentAdminSubject = new BehaviorSubject<Admin>(JSON.parse(localStorage.getItem('currentAdmin')));
        this.currentAdmin = this.currentAdminSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    public get currentDoctorValue(): Doctor {
        return this.currentDoctorSubject.value;
    }
    public get currentAdminValue(): Admin {
        return this.currentAdminSubject.value;
    }

    //Doctor login api
    doctorlogin(email: string, password: string, type: string, phoneNumber: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/login/doctor`, { email, password, type, phoneNumber })
            .pipe(map(doctor => {



                doctor.authdata = doctor.token
                localStorage.setItem('currentDoctor', JSON.stringify(doctor));

                this.currentDoctorSubject.next(doctor);
                return doctor;
            }));
    }

    //Admin login api
    adminlogin(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/auth/Adminlogin`, { email, password })
            .pipe(map(admin => {



                admin.authdata = admin.token
                localStorage.setItem('currentAdmin', JSON.stringify(admin));

                this.currentAdminSubject.next(admin);
                return admin;
            }));
    }

    //Patient login api
    login(email: string, password: string, type: string, phoneNumber: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/login/patient`, { email, password, type, phoneNumber })
            .pipe(map(user => {



                user.authdata = user.token
                localStorage.setItem('currentUser', JSON.stringify(user));

                this.currentUserSubject.next(user);
                return user;
            }));
    }

    //Patient logout api
    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        localStorage.removeItem('createroom')
        localStorage.removeItem('doctors')
        delete localStorage[ 'doctors' ];
        localStorage.clear();
    }

    //Doctor logout api
    logoutdoctor() {
        localStorage.removeItem('currentDoctor');
        this.currentDoctorSubject.next(null);
        localStorage.removeItem('createroom')
        localStorage.removeItem('doctors')
        localStorage.clear();
    }

    //Admin logout api
    logoutadmin() {
        localStorage.removeItem('currentAdmin');
        this.currentAdminSubject.next(null);
        localStorage.removeItem('doctors')
        localStorage.clear();
    }

    //Patient register api
    patientregister(email: string, Password: string, FirstName: string, LastName: string, Gender: string, Birthdate: string, PhoneNumber: string, Street1: string, City: string, State: string, Zip: string) {

        return this.http.post<Patientsignup>(`${environment.apiUrl}/api/registration/registerPatient`, { email, Password, FirstName, LastName, Gender, Birthdate, PhoneNumber, Street1, City, State, Zip })
            .pipe(map(res => {
                return res;
            }));
    }

    //Doctor register api
    doctorregister(email: string, Password: string, FirstName: string, LastName: string, Gender: string, Birthdate: string, PhoneNumber: string, Street1: string, City: string, State: string, Zip: string, Type: string) {
        return this.http.post<Doctorsignup>(`${environment.apiUrl}/api/registration/registerDoctor`, { email, Password, FirstName, LastName, Gender, Birthdate, PhoneNumber, Street1, City, State, Zip, Type })
            .pipe(map(res => {
                return res;
            }));
    }
    // both user use same api

    registerUser(email: string, Password: string, FirstName: string, LastName: string, Gender: string, Birthdate: string, PhoneNumber: string, Street1: string, City: string, State: string, Zip: string, registrationType: string) {
        return this.http.post<Doctorsignup>(`${environment.apiUrl}/api/registration/registerUser`, { email, Password, FirstName, LastName, Gender, Birthdate, PhoneNumber, Street1, City, State, Zip, registrationType })
            .pipe(map(res => {
                return res;
            }));
    }
    //Email otp verify api
    emailotpvarify(
        type: string,
        email: string,
        otp: string) {

        return this.http.post<any>(`${environment.apiUrl}/api/registration/otpVerification`, {
            type,
            email,
            otp,
        })
            .pipe(map(res => {
                return res;
            }));

    }

    //Phone otp verify api
    phoneotpvarify(
        Type: string,
        PhoneNumber: string,
        OTP: string) {

        return this.http.post<any>(`${environment.apiUrl}/api/registration/otpVerification`, {
            Type,
            PhoneNumber,
            OTP,
        })
            .pipe(map(res => {
                return res;
            }));

    }

    //Send otp api
    sendotp(email: string, UserId: string, PhoneNumber: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/registration/sendOtpVerification`, {
            email,
            UserId,
            PhoneNumber
        })
            .pipe(map(res => {
                return res;
            }));

    }

    //Forgot password api
    forgotpaswword(email: string, PhoneNumber: string) {
        return this.http.post<Forgotpassword>(`${environment.apiUrl}/api/registration/forgotPassword`, {
            email,
            PhoneNumber
        })
            .pipe(map(res => {
                return res;
            }));
    }

    //Change password api
    changepaswword(Email: string, PhoneNumber: string, Password: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/registration/changePassword`, {
            Email,
            PhoneNumber,
            Password
        })
            .pipe(map(res => {
                return res;
            }));
    }



    //Change temp password api
    changeTemp(email: string, phoneNumber: string, oldPassword: string, newPassword: string) {
        return this.http.post<changeEmailandPhone>(`${environment.apiUrl}/api/registration/ChangeTempPassword`, { email, phoneNumber, oldPassword, newPassword })
            .pipe(map(res => {
                return res;
            }));
    }

    //Change email api
    changeEmail(userId: string, oldEmail: string, newEmail: string) {
        return this.http.post<changeEmailandPhone>(`${environment.apiUrl}/api/updateProfile/UpdateEmailProfile`, { userId, oldEmail, newEmail })
            .pipe(map(res => {
                return res;
            }));
    }

    //Change phone api
    changePhone(userId: string, oldPhonenumber: string, newPhonenumber: string) {
        return this.http.post<changeEmailandPhone>(`${environment.apiUrl}/api/updateProfile/UpdatePhoneProfile`, { userId, oldPhonenumber, newPhonenumber })
            .pipe(map(res => {
                return res;
            }));
    }
}