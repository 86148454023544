import { Injectable } from "@angular/core";
import { Router } from '@angular/router'
import { AuthenticationService } from '~services/authentication.service';
import { DataService } from '~services/DataService.service'
// import {} from ''
// const store = require('store');

const MINUTES_UNITL_AUTO_LOGOUT = 240 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY = 'lastAction';
@Injectable()
export class AutoLogoutService {
  interval;
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router,
    private dataService: DataService,
    private authenticationService: AuthenticationService) {

    // this.auth = authService;
    this.check();
    this.initListener();
    this.initInterval();
    this.dataService.interval.subscribe((data) => {
      this.interval = data
    });
    // localStorage.setItem(STORE_KEY,Date.now().toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }
  stopNotificationinterval() {
    clearInterval(this.interval);
  }
  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    // if (isTimeout && this.auth.loggedIn)
    if (isTimeout) {

      const currentUser = this.authenticationService.currentUserValue;
      const currentDoctor = this.authenticationService.currentDoctorValue;
      const currentAdmin = this.authenticationService.currentAdminValue;
      if (currentUser || currentDoctor || currentAdmin) {
        if (currentUser) {
          // alert('user');
          // localStorage.clear();
          this.authenticationService.logout();
          this.stopNotificationinterval()
          this.router.navigate([ '/patient/patient-login' ]);
        }
        if (currentDoctor) {
          // alert('doctor');
          // localStorage.clear();

          this.authenticationService.logoutdoctor();
          this.stopNotificationinterval()
          this.router.navigate([ '/doctor/doctor-login' ]);
        }
        if (currentAdmin) {
          // alert('admin');
          // localStorage.clear();
          this.authenticationService.logoutadmin();
          this.stopNotificationinterval()
          this.router.navigate([ '/admin/admin-login' ]);
        }

      }


    }
  }
}
