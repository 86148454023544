import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class DataService {

  dataUpdated: EventEmitter<any> = new EventEmitter();
  newdataUpdated: EventEmitter<any> = new EventEmitter();
  nameUpdate: EventEmitter<any> = new EventEmitter();
  interval: EventEmitter<any> = new EventEmitter();
  constructor() { }

  setLatestData(data) {
    this.dataUpdated.emit(data);
  }
  setLatestnewdata(data) {
    this.newdataUpdated.emit(data);
  }
  setLatestnameDate(data) {
    this.nameUpdate.emit(data);
  }
  setInterval(data) {
    this.interval.emit(data);
  }
}