import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [];
@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'website/home', pathMatch: 'full' },
        // { path: 'app', component: WebsiteLayoutComponent, children: [
        //   { path: 'home', component: HomeComponent, pathMatch: 'full' },
        // ] },
        { path: 'admin', loadChildren: () => import('./component/admin/admin.module').then(m => m.AdminModule) },
        { path: 'doctor', loadChildren: () => import('./component/doctor/doctor.module').then(m => m.DoctorModule) },
        { path: 'patient', loadChildren: () => import('./component/patient/patient.module').then(m => m.PatientModule) },
        { path: 'pages', loadChildren: () => import('./shared/component/pages/pages.module').then(m => m.PagesModule) },
        { path: 'website', loadChildren: () => import('./component/website/website.module').then(m => m.WebsiteModule) }
        //{ path: '**', redirectTo: '/pages/404' },
      ],
      { useHash: true, anchorScrolling: 'enabled' }
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
